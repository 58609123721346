import { Capacitor } from "@capacitor/core";
import { DeviceInfo } from "@models/shared/device-info";
import { platform } from '@env/platform';

type NameVer = { name: string, value: string, version: string };
export function getDeviceInfo(): DeviceInfo {
    const header = [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, (window as any).opera];
    const dataOs: NameVer[] = [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' }
    ];
    const dataBrowser: NameVer[] = [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ];
    const agent = header.join(' ');
    const os = matchItem(agent, dataOs);
    const browser: any = matchItem(agent, dataBrowser);
    browser.navigator = {
        userAgent: navigator.userAgent,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        vendor: navigator.vendor
    };
    const clientType = platform == 'cef' ? 'cef' : (Capacitor.getPlatform() == 'web' ? 'web' : 'mobile');
    return new DeviceInfo({ os, browser, clientType });
}

function matchItem(st: string, data: NameVer[]): { name: string, version: string } {
    for (let i = 0; i < data.length; i += 1) {
        const regex = new RegExp(data[i].value, 'i');
        const match = regex.test(st);
        if (match) {
            const regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
            let matches: any = st.match(regexv);
            let version = '';
            if (matches && matches[1]) {
                matches = matches[1];
            }
            if (matches) {
                matches = matches.split(/[._]+/);
                for (let j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                        version += matches[j] + '.';
                    }
                    else {
                        version += matches[j];
                    }
                }
            }
            else {
                version = '0';
            }
            return {
                name: data[i].name,
                version: '' + parseFloat(version)
            };
        }
    }
    return { name: 'unknown', version: 'unknown' };
}

// export function isElectron(): boolean {
//     // Renderer process
//     if (typeof window !== 'undefined' && typeof window.process === 'object' && (window.process as any).type === 'renderer') {
//         return true;
//     }

//     // Main process
//     if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
//         return true;
//     }

//     // Detect the user agent when the `nodeIntegration` option is set to false
//     if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
//         return true;
//     }

//     return false;
// }
